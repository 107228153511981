<template>
  <div class="locale-changer">
      <div v-for="(lang, i) in langs" :key="`Lang${i}`">
        <button
          v-on:click="onLocaleChange($i18n, lang.lang)"
          class="locale-button"
          :class="{ 'locale-button--active': $i18n.locale === lang.lang }"
          :title="langs[i].title"
        >
          {{ langs[i].lang }}
        </button>
      </div>
  </div>
</template>

<script>
export default {
  name: 'LocaleChanger',
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.language
      },
    }
  },
  data () {
    return {
      langs: [
        {'lang': 'en',
         'title': 'English version'
        },
        {'lang': 'de',
         'title':'Deutsche Version'
        }
      ],
      language: undefined,
    }
  },
  methods: {
    onLocaleChange: function ($i18n, lang) {
      localStorage.setItem('locale', lang)
      $i18n.locale = lang
      this.language = lang
    }
  }
}
</script>

<style lang="scss" scoped>
  .locale {
    &-changer {
      @include from-until($tablet, 1180px) {
        margin-left: $container-padding * 2 - 20px;
      }

      @include font-size(16);

      display: inline-flex;
      font-family: $corporate-font-family;
      margin-left: $container-padding * 2;

      div {
        &:first-child {
          border-right: 1px solid $color-corporate-lightest;
        }
      }
    }

    &-button {
      color: $color-corporate;
      text-transform: uppercase;
      background-color: transparent;
      border: none;
      padding: 0 .3rem;
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      &:focus,
      &:hover {
        outline: none;
      }

      &--active {
        font-family: $corporate-font-family-strong;
      }
    }
  }
</style>
