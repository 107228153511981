<template>
  <headroom>
    <header class="header" :class="{ 'header--home': this.$route.name === 'home'}">
      <div class="header__container">
        <div
          class="header__wrapper" v-bind:class="{ 'header__wrapper--nav-active' : showMobileMenu }">
          <router-link
            class="header__logo"
            to="/"
            v-on:click.native="showMobileMenu = false"
          >
            Gerling Garden
          </router-link>
          <div class="header__navigation">
            <router-link v-for="(link, i) in this.json.navigation.top"
              :key="`link${i}`"
              class="header__navigation-link"
              :to="i ? { path: link.path } : { path: '/' }"
              v-on:click.native="showMobileMenu = false"
              v-bind:class="{ 'header__navigation-link--active': isActivePage(link.path) }"
            >
              {{ $t('navigation.top')[i].title }}
            </router-link>
            <LocaleChanger />
          </div>
          <button class="header__navigation-toggle" v-on:click="showMobileMenu = !showMobileMenu">
            <span class="header__navigation-toggle-bar header__navigation-toggle-bar--top"></span>
            <span class="header__navigation-toggle-bar header__navigation-toggle-bar--center"></span>
            <span class="header__navigation-toggle-bar header__navigation-toggle-bar--bottom"></span>
          </button>
        </div>
      </div>
    </header>
  </headroom>
</template>

<script>
import { headroom } from 'vue-headroom'
import LocaleChanger from '@/components/LocaleChanger.vue';

export default {
  name: 'Header',
  components: {
    headroom,
    LocaleChanger,
  },
  data () {
    return {
      showMobileMenu: false,
    }
  },
  created () {
    if(this.$i18n.locale === 'en'){
      this.json = require('@/locales/en.json');
    }
    else{
      this.json = require('@/locales/de.json');
    }
  },
  methods: {
    isActivePage (path) {
      return this.$route.name === path
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  @include until($desktop) {
    z-index: 5;
  }

  background-color: $color-shade-lighter;
  width: 100vw;

  &__container {
    @extend %container-max-width-center;
  }

  &__wrapper {
    @include responsive-container-padding;

    display: flex;
    align-items: center;
    height: $header-height;
    justify-content: space-between;
  }

  &__logo {
    $logo-height: 53px;
    $logo-width: 262px;

    @include until($desktop) {
      width: $logo-width;

      .header__wrapper--nav-active & {
        opacity: 0;
      }
    }

    @include desktop {
      background-image: url('../assets/images/logo.svg');
    }

    background: url('../assets/images/logo_with-text.svg') no-repeat;
    background-size: contain;
    display: block;
    height: $logo-height;
    overflow: hidden;
    text-indent: 110%;
    transition: all $transition-duration*2 $transition-easing;
    white-space: nowrap;

  }

  &__navigation {
    @include until($desktop) {
      align-items: center;
      flex-direction: column;
      height: calc(100vh - #{$header-height-mobile});
      left: 0;
      margin: $header-height-mobile 0 0;
      opacity: 0;
      overflow-y: scroll;
      padding-top: 2rem;
      pointer-events: none;
      position: absolute;
      text-align: center;
      top: 0;
      transition: background-color 0s 0s $transition-easing;
      width: 100vw;

      .header__wrapper--nav-active & {
        background-color: $color-shade-lighter;
        opacity: 1;
        pointer-events: all;
        transition-delay: .5s;
      }

      &:before,
      &:after {
        background: $color-shade-lighter;
        bottom: 0;
        content: '';
        opacity: 0;
        position: absolute;
        top: 0;
        transition: all $transition-duration*2 $transition-easing;
        width: 100%;
        z-index: -1;
      }

      &:before {
        clip-path: polygon(100% 0,0 100%,0 0);
        left: 0;
        transform: translateY(-80%);

        .header__wrapper--nav-active & {
          opacity: 1;
          transform: translateY(0);
        }
      }

      &:after {
        clip-path: polygon(100% 0,0 100%,100% 100%);
        right: 0;
        transform: translateY(80%);

        .header__wrapper--nav-active & {
          opacity: 1;
          transform: translateY(-1px);
        }
      }


    }

    display: flex;
  }

  &__navigation-link {
    @include until($desktop) {
      @include font-size(24);

      opacity: 0;
      transform: translateY(-2rem);
      transition: all $transition-duration*2 $transition-easing;
      margin: 1rem 0;

      .header__wrapper--nav-active & {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @include from-until($tablet, 1180px) {
      margin-left: $container-padding * 2 - 20px;
    }


    @include font-size(16);
    font-family: $corporate-font-family;
    text-transform: uppercase;
    color: $color-corporate;
    margin-left: $container-padding * 2;
    font-weight: 400;
    position: relative;

    &::after {
      content: '';
      width: 0;
      height: 3px;
      background-color: $color-corporate;
      transition: width $transition-duration*2 $transition-easing;
    }

    &:hover,
    &:active {
      @include underline-navi;

      color: $color-corporate-medium;
    }

    &--active {
      @include underline-navi;

      font-family: $corporate-font-family-strong;
    }
  }

  &__navigation-toggle {
      @include until($desktop) {
        display: flex;
      }

      align-items: center;
      background: transparent;
      border: 0;
      cursor: pointer;
      display: none;
      flex-direction: column;
      height: 2.5rem;
      justify-content: center;
      outline: 0;
      padding: 0;
      position: relative;
      transition: all $transition-duration $transition-easing;
      width: 40px;
      z-index: 2;

      .header__wrapper--nav-active & {
        transform: rotate(45deg);
      }

      &-bar {
        background-color: $color-corporate;
        display: block;
        height: 2px;
        margin: 3px 0;
        transform-origin: center;
        transition: all $transition-duration $transition-easing;
        width: 30px;

        .header__wrapper--nav-active & {

          &--top {
            margin: 0;
            transform: rotate(0) translate(0,2.5px);
          }

          &--center {
            height: 0;
            margin: 0;
            opacity: 0;
            width: 0;
          }

          &--bottom {
            margin: 0;
            transform: rotate(-90deg) translate(0.5px,0);
          }
        }
      }
    }
}
</style>
