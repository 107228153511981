import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.ROUTER_BASE,
  routes: [
    {
      path: '/',
      name: 'gerlinggarden',
      component: () => import('./views/GerlingGarden.vue'),
      meta: {
        title: 'Gerling Garden',
      }
    },
    {
      path: '/ensemble',
      name: 'ensemble',
      component: () => import('./views/Ensemble.vue'),
    },
    {
      path: '/gateandhall',
      name: 'gateandhall',
      component: () => import('./views/GateAndHall.vue'),
      meta: {
        title: 'Gerling Garden - THE GATE & THE HALL',
      },
    },
    {
      path: '/lime',
      name: 'lime',
      component: () => import('./views/Lime.vue'),
      meta: {
        title: 'Gerling Garden - THE LIME',
      },
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/Contact.vue'),
      meta: {
        title: 'Gerling Garden - Kontakt',
      }
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: () => import('./views/Imprint.vue'),
      meta: {
        title: 'Gerling Garden - Impressum',
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('./views/Privacy.vue'),
      meta: {
        title: 'Gerling Garden - Datenschutz',
      }
    },
  ],
  scrollBehavior() {
    return window.scrollTo({ top: 1, behavior: 'instant' }); // top: 1; Firefox Bug if 0;
  },
})
