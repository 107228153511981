import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import VueMeta from 'vue-meta'
import AOS from 'simple-aos'
import smoothscroll from 'smoothscroll-polyfill'
// import VueCookieBot from '@ambitiondev/vue-cookiebot-plugin'
import VueLazyload from 'vue-lazyload'
// import * as klaro from 'klaro'

import 'klaro/dist/klaro.css'
import './assets/styles/gerlinggarden.scss'

Vue.config.productionTip = true
// const isProd = process.env.NODE_ENV === 'production'

smoothscroll.polyfill();

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1
})

// Vue.use(VueCookieBot, {
//   cookieBotID: '32f9bd8d-4e8b-4077-a2a0-6c370a0884dd'
// })

Vue.use(VueMeta)

// Vue.use(klaro)

AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init", // class applied after initialization
  animatedClassName: "aos-animate", // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 100, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 800, // values from 0 to 3000, with step 50ms
  easing: "ease-in-out-sine", // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
});

new Vue({
  router,
  i18n,
  render: h => h(App),
  metaInfo: {
    htmlAttrs: {
      lang: localStorage.getItem('locale'),
    },
  },
  mounted: function() {
    if (localStorage.getItem('locale')) {
      i18n.locale = localStorage.getItem('locale')
    }
  }
}).$mount('#app')
