<template>
  <div id="app">
    <Header/>
    <main class="main">
      <router-view/>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer,
  },
  metaInfo () {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      },
    }
  },
  mounted () {
    // this.$cookiebot.consentBanner({
    //   async: true,
    //   locale: this.$i18n.locale // tip: replace 'en' with this.$i18n.locale when using vue-i18n
    // })
  }
}
</script>

<style scoped lang="scss">

</style>
